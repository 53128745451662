<template>
  <div class="section-content section-commercial pt-4 pb-5 w-100 container">
    <b-container class="container-xlg m-auto pl-xxl-0 pr-xxl-0">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="Specified Commercial Transaction Act"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="特定商取引法に関する表記"
        />
      </div>
      <div class="term-content mt-2 mb-0 mb-lg-5">
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">事業者</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">株式会社メルメクス</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">所在地</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">150-0002　東京都渋谷区渋谷3-10-15</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">代表者</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">森雄作</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">ホームページURL</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0"><b-link href="http://www.melmax.co.jp/" target="_blank">http://www.melmax.co.jp/</b-link></h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">メールアドレス</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0"><b-link href="mailto:info@melmax.co.jp" target="_blank">info@melmax.co.jp</b-link></h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">販売価格</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">コンテンツによって異なります。各コンテンツの購入画面に表示いたします。表示価格には消費税を含みます。</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">上記以外に必要となる料金</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">通信料</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">コンテンツ・サービスの提供時期</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">決済処理完了後、アクセスにより即時</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">代金支払い方法</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">対象となるコンテンツの提供前</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">返品について</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">商品等の性質上、返品／キャンセルはできません。<br />購入後のコイン、ポイントの返品／キャンセルはできません。</h4></b-col>
          </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
    name: "SectionCommercial"
}
</script>