<template>
  <div class="term-privacy-page w-100">
    <SectionCommercial />
    <SectionPhone class="mt-5 pb-3 pt-3 pb-md-5 pb-xxl-5 bg-white" />
  </div>
</template>

<script>
import SectionCommercial from './SectionCommercial'
import SectionPhone from "@/views/Landing/SectionPhone";

export default {
  name: "Commercial",
  title: "commercial",
  components: {
    SectionPhone,
    SectionCommercial
  },
};
</script>